<template>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="secondary">
        <b-container>
            <b-navbar-brand v-on:click="onClickRedChirpLogo"><img src="@/assets/redchirp-logo-on-dark-grey.png" width="200px"/></b-navbar-brand>
            
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                   <b-navbar-nav>
                        <b-nav-item v-on:click="onClickStartHere" :active="start_here_active" style="position: relative; top: 0.4rem;">Start Here</b-nav-item>
                        <b-nav-item v-on:click="onClickCommerce7" :active="commerce7_active" style="position: relative; top: 0.4rem;" v-if="show_commerce7">Commerce7</b-nav-item>
                        <b-nav-item v-on:click="onClickPricing" :active="pricing_active" style="position: relative; top: 0.4rem;">Pricing</b-nav-item>
                        <b-nav-item href="https://app.redchirp.com/app/sign-in" style="position: relative; top: 0.4rem;">Sign In</b-nav-item>

                         <b-nav-item>
                           <b-btn 
                            variant="primary" 
                            v-on:click="onClickGetDemo"
                            
                           >
                            Get a Demo
                          </b-btn>
                        </b-nav-item>

                    </b-navbar-nav>
              </b-navbar-nav>
            </b-collapse>
          </b-container>
      </b-navbar>
    </div>  
</template>

<script>
export default {
    data() {
        return {
            meal: ''
        }
    },
  methods: {
    onClickStartHere() {
      let industry = localStorage.getItem('industry');
      if ( industry == null ) industry = 'wine';

      this.$router.push({ name: industry+'-start-here' });
    },

    onClickPricing() {
      this.$router.push({ name: 'pricing' })
    },

    onClickRedChirpLogo() {
      this.onClickStartHere();
    },

    onClickGetDemo()
    {
       window.open("https://calendly.com/jennie-gilbert/redchirp-demo-30", "_blank");
    },

    onClickCommerce7()
    {
      this.$router.push({ name: 'wine-commerce7' })
    },
  },

  computed: {
    start_here_active() {
      return this.$route.name == 'vets-start-here' || this.$route.name == 'wine-start-here';
    },

    commerce7_active() {
      return this.$route.name == 'wine-commerce7';
    },


    pricing_active() {
      return this.$route.name == 'pricing';
    },

    show_commerce7() {
      let name = (''+this.$route.name).toLowerCase();
      return !name.includes('vets');
    }
  },
}    
</script>

<style lang="scss" scoped>

</style>