<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: --secondary;
}




.text
{
  font-size: 50px; 
  font-weight: 900; 
  color: var(--secondary);
}

.text2
{
  font-size: 40px; 
  font-weight: 900; 
  color: var(--secondary);
  margin-top: 15px;
  margin-bottom: 15px;
}

.text3
{
  font-size: 30px; 
  font-weight: 700; 
  color: var(--secondary);
}

.check_list
{
  margin-top: 15px;
  margin-left: 15px;
}

.check_list_icon
{
  color: var(--primary);
  margin-right: 5px;
}

.checklist
{
  margin-top: 15px;
}

.checklist li i
{
  color: var(--primary);
}

.checklist li:not(:last-child) {
    margin-bottom: 15px;
}


</style>
