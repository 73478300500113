<template>
<m-standard-pageframe>

    <b-container>
        <div class="hero_headline" style="margin-bottom: 0.3em; margin-top: 0.3em;">
          RedChirp + Commerce7<br/>
          Better Together!
        </div>
    </b-container>

  <b-container>
      <div style="max-width: 650px; margin: 0 auto">
      <vimeo-player 
        ref="player" 
        video-id="629515974" 
        
        style="margin-top: 30px; margin-bottom: 30px;"
        class="embed-container" :options="{'responsive':true}"
      />
      </div>
  </b-container>
     
    

    <m-ribbon>

      <template v-slot:content>
        
        <div class="hero_headline" style="margin-bottom: 0.3em;">
          Contact Sharing
        </div>

        <div class="hero_tagline">
          Save time and avoid re-entering data over and over. With RedChirp's Commerce7 integration, all your Commerce7 customers will automatically be contacts waiting for you in RedChirp. 
        </div>  

       

        <m-check-list>
          <m-check-list-item><b>Search in RedChirp</b> &mdash; Find all your Commerce7 customers in RedChirp. Search, find and start texting with one click.</m-check-list-item>
          <m-check-list-item><b>Start texting with one click</b> &mdash; Search for your customer and click the phone number you want to text. That's it!</m-check-list-item>
          <m-check-list-item><b>Real-time syncing</b> &mdash; Make a change or add a new customer in Commerce7? RedChirp will automatically update your contact in real time.</m-check-list-item>
        </m-check-list>
      </template>

      <template v-slot:decoration>
        <img src="@/assets/wine/commerce7-contacts.png" width="550px"/>
      </template>

    </m-ribbon>

 

    <m-ribbon left>
        <template v-slot:content>

          <div class="content_headline">
            View in Commerce7
          </div>

          <div class="hero_tagline">
            Seamlessly jump to view any customer in Commerce7 right from RedChirp. One click is all you need whether it's before you text, or even in the middle of a conversation. 
          </div>  

          <m-check-list>
            <m-check-list-item><b>Check details before you text </b> &mdash; Search for your customer in RedChirp and click to view them in Commerce7 if you need to look up anything before you text. </m-check-list-item>
            <m-check-list-item><b>Get more information while texting</b> &mdash; Are they really a current club member? During any conversation click to view their profile in Commerce7 to confirm.</m-check-list-item>
            <m-check-list-item><b>Matching is automatic</b> &mdash; RedChirp matches people by their phone numbers. Same phone number for multiple customers? No problem! Select the one you want from a list of all applicable customers.</m-check-list-item>
          </m-check-list>
        </template>

        <template v-slot:decoration>
          <img src="@/assets/wine/commerce7-view-from-red-chirp.png" width="350px" style="border-radius: 25px;"/>
        </template>

    </m-ribbon>


    <div class="alt_ribbon">

      <m-ribbon left>

        <template v-slot:content>
        <div class="content_headline">
          Conversation Tracking
        </div>
        

          <div class="hero_tagline">
            See every text conversation anyone on your team has had with a customer, right inside Commerce7 at a glance. No copying and pasting or manual labor required. It's all synced automatically!
          </div>

          <m-check-list>
            <m-check-list-item><b>Know who's texted your customer</b> &mdash; When looking at a customer in Commerce7, view the RedChirp tab to see a list of every team member who's texted with them.</m-check-list-item>
            <m-check-list-item><b>View any conversation any time</b> &mdash; Click “View” and we'll take you directly to that specific conversation in RedChirp. It's always live, in real time and up to date. RedChirp's built for teamwork so you can always view the entire conversation, even if someone else on your team has been the one having it.  </m-check-list-item>
            <m-check-list-item><b>Quickly see what's new</b> &mdash; The RedChirp tab will include how many messages have gone back and forth in each conversation, when it started and the date of the most recent text as well.</m-check-list-item>
          </m-check-list>
        </template>

        <template v-slot:decoration>
          <img src="@/assets/wine/commerce7-view-conversations.png" width="450px"/>
        </template>

      </m-ribbon>


      <m-ribbon>
        
        <template v-slot:content>

          <div class="content_headline">
            Start texting from inside Commerce7
          </div>

          <div class="hero_tagline">
            Working in Commerce7 and realize you need to text a customer? There's no need to open a new window, log into RedChirp and find them. Start texting them right from within Commerce7.
          </div>

          <m-check-list>
              <m-check-list-item><b>Install our Commerce7 App</b> &mdash; Unlock an even deeper integration by installing our app. See all previous RedChirp conversations, and initiate new ones, right from within Commerce7.</m-check-list-item>
              <m-check-list-item><b>Jump to any customer in RedChirp </b> &mdash; Looking at a customer in Commerce7? More Actions > “View in RedChirp” takes you to the same contact in RedChirp.</m-check-list-item>
              <m-check-list-item><b>Start texting with one click</b> &mdash; The RedChirp tab lets you start texting that customer with one click.</m-check-list-item>
            </m-check-list>
        </template>

        <template v-slot:decoration>
          <img src="@/assets/wine/commerce7-text-from-commerce7.png" width="450px"/>
        </template>

      </m-ribbon>

      
    </div>
      

    <m-ribbon>
        
        <template v-slot:content>

          <div style="font-size: 2em; margin-bottom: 0.35em">
            <p>We loved RedChirp even before the Commerce7 integration, but now we're even more excited because we can access the conversations directly in our CRM, giving us better insight into how our customers communicate with us.</p>
            <p>This integration has provided our team with incredibly dynamic tools to deliver best-in-class customer service.</p>
          </div>

          <div style="text-align: right; font-size: 2em;">
            Ahna Jotter
          </div>
          <div style="text-align: right; font-size: 1.2em;">
            Direct to Consumer Manager at Hess Persson Estates
          </div>
          
        </template>

        <template v-slot:decoration>
          <img src="@/assets/wine/wine2.jpg" width="350px" style=" 145px; margin-bottom: 15px; border-radius: 25px;"/>
        </template>

      </m-ribbon>

    

  <div class="alt_ribbon">
    <b-container style="margin-top: 6em; padding-bottom: 6em;">
      <div class="text2">Get started with your personalized demo!</div>
      <b-btn variant="primary" size="lg" style="margin-top: 2em; margin-bottom: 2em;" v-on:click="onClickGetDemo">Book a Demo</b-btn>
    </b-container>
  </div>

</m-standard-pageframe>
</template>

<script>

  export default {
      data() {
        return {
          form_value: '',
        }
      },


      mounted () {
        localStorage.setItem('industry','wine');
      },

      methods: {
        onClickGetRedChirpFree() 
        {
          this.$router.push({ 
            name: 'sign-up',
            params: { 'value' : this.form_value }
          });
        },

        onClickGetDemo()
        {
          window.open("https://calendly.com/jennie-gilbert/redchirp-demo-30", "_blank");
        },
      },
  }
</script>

<style lang="scss" scoped>
.embed-container {
  width: 100% !important;
}
</style>