<template>
<div>

    <img
        style="margin-top: 25px; margin-bottom: 15px;" 
        src="@/client-pages/studio41/studio-41-logo.png"
    />

    <b-container
        style="text-align: left;"
        v-if="stage == 'form'"
    >

    <div class="hero_headline mt-3 mb-3" style="text-align: center">
        Check-in
    </div>  

    <b-form  autocomplete="off">

       <m-select
            label="Showroom"
            :options="showroom_options"
            v-model="showroom"
            :autofocus="showroom == null"
            v-on:input="onChangeShowroom($event)"
       />

       <m-text-field
            label="First Name *"
            v-model="first_name"
            :autofocus="showroom != null"
       />
       
        <m-text-field
            label="Last Name *"
            v-model="last_name"
       />

        <m-text-field
            label="Cell Phone Number *"
            v-model="mobile"
       />

       <m-text-field
            label="What do you need help with today?"
            v-model="message"
            textarea
       />

       <small class="text-muted">
           By submitting this form the customer agrees to receive text messages.  Message and/or data rates may apply.
       </small>

       <div style="text-align: right;" class="mb-5">
        <b-btn variant="primary" v-on:click="onClickGetStarted" :disabled="!is_valid">
            Check In
        </b-btn>
        </div>

    </b-form>

    </b-container>


     <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'spin'"
    >
    <div style="text-align: center; margin-top: 5em; margin-bottom: 5em;">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    </b-container>


    <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'thanks'"
    >
    <div class="hero_headline mt-3 mb-3" style="text-align: center">
        Check in recorded.

        <div style="text-align: right;" class="mb-5">
            <b-btn variant="primary" v-on:click="onClickCheckInAnother">
                Check In Another Customer
            </b-btn>
        </div>
    </div>  
    </b-container>

</div>
</template>

<script>
const axios = require('axios');

export default {
    data() {
        return {
            first_name: '',
            last_name: '',
            mobile: '',
            message: '',

            stage: 'form',

            showroom_options: [
                { 'text' : 'Please select a showroom', 'value' : null },
                { 'text' : 'Naperville', 'value' : '9d06496a-b77e-424c-ae88-6996ad285c4e' },
                { 'text' : 'Palatine', 'value' : '773cea4f-2499-4abf-a8f1-8c96f1bf4ad6' },
                { 'text' : 'Pulaski', 'value' : 'ae0b2bff-a596-4f51-9a65-e647d936dca6' },
            ],

            showroom: null,
        }
    },

    mounted () {
        this.showroom = localStorage.getItem('studio41-showroom');
    },

    methods: 
    {
        createRequest()
        {
            return {
				'first_name' : this.first_name,
				'last_name' : this.last_name,
				'mobile' : this.mobile,
				'submitted_from_url' : window.location.href,
                'production': true,

				'extra_fields' : [
					{ 'text' : 'question_or_comment', 'value' : this.message },
				],
			};
        },

        onClickCheckInAnother()
        {   
            this.first_name = '';
            this.last_name = '';
            this.mobile = '';
            this.message = '';
            this.stage = 'form';
        },

        onClickGetStarted()
        {
            this.stage = 'spin';

            let axios_data = JSON.stringify(this.createRequest());
            let axios_headers = {'Content-type':'application/json; charset=UTF-8'};
            let inbox_id = this.showroom;

            let axios_request = {
                'url' : 'https://app.redchirp.com/api/v1/contact-requests/form-connect/bec99dec-50d0-43ca-b461-3536298896ce/'+inbox_id,
                'method' : 'post',
                'data' : axios_data,
                'headers' : axios_headers,
                'timeout' : 15000, 
            };

            axios.request(axios_request)
            .then( () => {
                this.stage = 'thanks';
            })
            .catch(() => 
                {
                    this.stage = 'form';
                    alert('Unable to submit form.  Please try again.');
                }
            );
        },

        createEndpointFromUSPhoneNumber(number, default_value)
        {
            let only_digits = '';

            for ( let i = 0; i < number.length; i++ )
            {
                let cur_char = number.charAt(i);

                if ( cur_char === '0' || cur_char === '1' || cur_char === '2' || cur_char === '3' || cur_char === '4' || cur_char === '5' || cur_char === '6' || cur_char === '7' || cur_char === '8' || cur_char === '9' )
                {
                    only_digits += cur_char;
                }
            }


            if ( only_digits.length == 10 )
                return 'E164:+1'+only_digits;

            if ( only_digits.length == 11 && only_digits.charAt(0) === '1' )
                return 'E164:+'+only_digits;

            return default_value;
        } ,

        isPhoneNumberValid()
        {
            return this.createEndpointFromUSPhoneNumber(this.mobile,null) != null;
        },

        isBlank(val)
        {
            if ( val == null ) return true;
            if ( val.trim() == '' ) return true;
            return false;
        },

        isEmail(val)
        {
            return !this.isBlank(val) && val.includes('@');
        },

        onChangeShowroom(new_showroom)
        {
            if ( new_showroom == null )
            {
                localStorage.removeItem('studio41-showroom');
            }
            else
            {
                localStorage.setItem('studio41-showroom', new_showroom); 
            }
        },
    },

    computed: {
        is_valid() {
            return this.isPhoneNumberValid() && + !this.isBlank(this.first_name) && !this.isBlank(this.last_name) && this.showroom != null;
        }
    },
}
</script>

<style lang="scss" scoped>
</style>