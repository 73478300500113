<template>
<footer class="border-top">
        <div class="row mt-5" >
          <div class="col-12 col-md">
            <!--<img class="mb-2" src="@/assets/redchirp-logo-with-tm.webp" alt="" width="255px">-->
            <p class="text-muted">&copy; RedChirp LLC, 2020 - {{year}}</p>
            <p class="text-muted">RedChirp&trade; is a trademark of RedChirp, LLC</p>
            <p>
                <a class="text-muted mr-3" href="/privacy-policy.pdf" target=_blank>Privacy Policy</a>
                <a class="text-muted" href="/terms-of-use.pdf" target=_blank>Terms of Use</a>
            </p>
          </div>
        </div>
      </footer>
</template>

<script>
export default {
    computed: {
        year() {
            return new Date().getFullYear();
        }
    },
}
</script>

<style lang="scss" scoped>

</style>