import Vue from 'vue'
import App from './App.vue'
import router from "./router";

import BootstrapVue from 'bootstrap-vue'
import './custom.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-awesome/icons'

import Icon from 'vue-awesome/components/Icon.vue'

Vue.use(BootstrapVue);
Vue.config.productionTip = false

Vue.component('icon', Icon)

import CheckList from '@/components/check-list.vue';
Vue.component('m-check-list', CheckList);

import CheckListItem from '@/components/check-list-item.vue';
Vue.component('m-check-list-item', CheckListItem);

import Ribbon from '@/components/ribbon.vue';
Vue.component('m-ribbon', Ribbon);

import NavBar from '@/components/navbar.vue';
Vue.component('m-navbar', NavBar);

import Footer from '@/components/footer.vue';
Vue.component('m-footer', Footer);

import StandardPageFrame from '@/components/standard-pageframe.vue';
Vue.component('m-standard-pageframe', StandardPageFrame);

import TextField from '@/components/text-field.vue';
Vue.component('m-text-field', TextField);

import Select from '@/components/select.vue';
Vue.component('m-select', Select);

import vueVimeoPlayer from 'vue-vimeo-player'


Vue.use(vueVimeoPlayer)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

