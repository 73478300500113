<template>
<m-standard-pageframe>

    <m-ribbon>

      <template v-slot:content>
        <div class="hero_headline" style="margin-bottom: 0.3em;">
          Good for Vets<br/>Good for Pets!<br/>
        </div>

        <div class="hero_tagline">
          RedChirp is the messaging platform for veterinarians.  <b>Connect with pet parents, save time and keep your work life separate from your personal life!</b>
        </div>  

        <b-input-group class="mt-3" style="padding-top: 7px; padding-bottom: 7px;">
          <b-form-input placeholder="Your cell number or email" v-model="form_value"></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" v-on:click="onClickGetRedChirpFree">Get RedChirp Free</b-button>
          </b-input-group-append>
        </b-input-group>

        <m-check-list>
          <m-check-list-item><b>Save time by texting</b> &mdash; even the quickest phone call still takes a few minutes, but sending a text only takes seconds.</m-check-list-item>
          <m-check-list-item><b>Keep your personal life separate from work</b> &mdash; You can give out your RedChirp number with confidence knowing you will never be bothered when you are off work.</m-check-list-item>
          <m-check-list-item><b>Stay Accessible</b> &mdash; RedChirp makes it easy to cover for co-workers and work together as a team to respond quickly.</m-check-list-item>
        </m-check-list>
      </template>

      <template v-slot:decoration>
        <img src="@/assets/vets/conversation.png" width="350px"/>
      </template>

    </m-ribbon>

    <div class="alt_ribbon">

      <b-container fluid class="ribbon_headline">
          Powerful tools to delight pet owners and grow your practice
      </b-container>

      <m-ribbon left>
        
        <template v-slot:content>

          <div class="content_headline">
            Pet owners can start a conversation with your staff from your website
          </div>

          <m-check-list>
            <m-check-list-item><b>Deploys in minutes</b> &mdash; takes only a few minutes to get on any website.</m-check-list-item>
            <m-check-list-item><b>Smart notifications</b> &mdash; RedChirp uses what it knows about availability and skills to connect a pet parents with the right person on your team in seconds.</m-check-list-item>
            <m-check-list-item><b>No Extra Staff Needed</b> &mdash; RedChirp lets teams work together gracefully, respond from anywhere and never step on each other's toes.</m-check-list-item>
            <m-check-list-item><b>Optimized by A.I.</b> &mdash; RedChirp uses powerful A.I. to automatically optimize the appearance of the chat control to encourage engagement.</m-check-list-item>
          </m-check-list>
        </template>

        <template v-slot:decoration>
          <img src="@/assets/vets/openings.png" width="300px"/>
        </template>

      </m-ribbon>

      <m-ribbon>
        
        <template v-slot:content>

          <div class="content_headline">
            Modernize your payments
          </div>

          <m-check-list>
              <m-check-list-item><b>Mobile payments</b> &mdash; Text your customers to collect payment information</m-check-list-item>
              <m-check-list-item><b>Totally Secure</b> &mdash; RedChirp uses military grade encryption end-to-end and is fully PCI/DSS compliant.</m-check-list-item>
              <m-check-list-item><b>Convenient For Customers</b> &mdash; Get pet parents on their way faster by collecting payment information before or after visits by text.</m-check-list-item>
              <m-check-list-item><b>Get Paid Faster</b> &mdash; The average RedChirp payment is fulfilled within half a day.</m-check-list-item>
              <m-check-list-item><b>Works with your current processor</b> &mdash; Works with every payment processor, no need to make costly processing changes.</m-check-list-item>
              <m-check-list-item><b>No additional fees</b> &mdash; RedChirp does not charge any additional monthly or per transaction fees to collect payments.</m-check-list-item>
            </m-check-list>
        </template>

        <template v-slot:decoration>
          <img src="@/assets/vets/payments.png" width="250px"/>
        </template>

      </m-ribbon>

      <m-ribbon left>
        
        <template v-slot:content>

          <div class="content_headline">
            Reduce missed appointments with text reminders
          </div>

          <m-check-list>
            <m-check-list-item><b>Send text reminders</b> &mdash; effortlessly schedule appointment reminder texts</m-check-list-item>
            <m-check-list-item><b>Two way enabled</b> &mdash; Should changes be required, pet owners can text you back to make arrangements.</m-check-list-item>
            <m-check-list-item><b>Fill openings</b> &mdash; Unexpected opening? Fill it by quickly texting pet owners that are waiting for an earlier appointment.</m-check-list-item>
            <m-check-list-item><b>Schedule overdue follow-ups</b> &mdash; Text when pets are overdue for vaccinations and other follow-ups for the fastest and easiest scheduling you’ve ever done.</m-check-list-item>
          </m-check-list>
          
        </template>

        <template v-slot:decoration>
          <img src="@/assets/vets/calendar-may.png" width="475px;" style="max-width: 100%"/>
        </template>

      </m-ribbon>

    </div>

    <m-ribbon>
        
        <template v-slot:content>

          <div class="content_headline">
            Delight Pet Parents With Curbside Pickup
          </div>

          <m-check-list>
            <m-check-list-item><b>Make it easy </b> &mdash; Let pet parents pick up food, medicine and other supplies from you without getting out of their car.</m-check-list-item>
            <m-check-list-item><b>No signage required</b> &mdash; RedChirp will text pet parents all the details they need, including how to let you know (right from the text!) when they’re on the way and have arrived.</m-check-list-item>
            <m-check-list-item><b>Work together</b> &mdash; RedChirp will notify the right people, find someone to bring the order out and make sure you never step on each other's toes.</m-check-list-item>
            <m-check-list-item><b>Unchain yourself</b> &mdash; Your team can effortlessly create, coordinate and fulfill curbside pickups from computers, tablets or cell phones so they’re never tied to a desk.</m-check-list-item>
          </m-check-list>
          
        </template>

        <template v-slot:decoration>
          <img src="@/assets/women-in-car.jpg" width="350px;" style="max-width: 100%; border-radius: 25px;"/>
        </template>

      </m-ribbon>

    <m-ribbon left>
        
        <template v-slot:content>

          <div class="content_headline">
            The Best Customers Feel Like Friends
          </div>

          <m-check-list>
            <m-check-list-item><b>Friends text each other</b> &mdash; Delight pet parents by communicating with them the way they communicate with the people they like and trust the most... by text!</m-check-list-item>
            <m-check-list-item><b>Right where you want to be</b> &mdash; Your practice will never be farther away from your best customers than their text history. </m-check-list-item>
            <m-check-list-item><b>Set yourself apart</b> &mdash; Delight pet parents with your accessibility and convenience and watch repeat business and referrals sky rocket.</m-check-list-item>
          </m-check-list>
          
        </template>

        <template v-slot:decoration>
          <img src="@/assets/women-on-phone-unsplash.jpg" width="350px;" style="max-width: 100%; border-radius: 25px;"/>
        </template>

      </m-ribbon>

      

    <m-ribbon>
        
        <template v-slot:content>

          <div style="font-size: 2em; margin-bottom: 0.5em">
            It is increadibly reassuring to know that my vet is only a text away.  It's like having a friend who is a vet!
          </div>

          <div style="text-align: right; font-size: 2em;">
            Jennie Gilbert
          </div>
          <div style="text-align: right; font-size: 1.2em;">
            Phoenix, AZ
          </div>
          
        </template>

        <template v-slot:decoration>
          <img src="@/assets/vets/dog2.jpg" width="350px" style=" 145px; margin-bottom: 15px; border-radius: 25px;"/>
        </template>

      </m-ribbon>

    

  <div class="alt_ribbon">
    <b-container style="margin-top: 6em; padding-bottom: 6em;">
      <div class="text2">Get started with your personalized demo!</div>

      <b-btn variant="primary" size="lg" style="margin-top: 2em; margin-bottom: 2em;" v-on:click="onClickGetDemo">Book a Demo</b-btn>
    </b-container>
  </div>

</m-standard-pageframe>
</template>

<script>
  export default {
      data() {
        return {
          form_value: '',
        }
      },

      mounted () {
        localStorage.setItem('industry','vets');
      },

      methods: {
        onClickGetRedChirpFree() 
        {
          this.$router.push({ 
            name: 'sign-up',
            params: { 'value' : this.form_value }
          });
        },

        onClickGetDemo()
        {
          window.open("https://calendly.com/jennie-gilbert/redchirp-demo-30", "_blank");
        },
      },
  }
</script>

<style lang="scss" scoped>

</style>