<template>
<m-standard-pageframe>

    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
      <div class="hero_headline" style='margin-bottom: 0.5em;'>RedChirp Pricing</div>
      <div class="hero_tagline" style='margin-bottom: 1.5em;'><b>Simple and flexible</b> &mdash; No long term commitments, change plans at any time.</div>
    </div>

    <div class="container">
      <div class="card-deck mb-4 text-center">
        <div class="card mb-4 box-shadow" style="min-width: 200px;">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Solopreneur</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">$99 <small class="text-muted">/ mo<sup style="font-size: 14px">*</sup></small></h1>
            <!-- <small class="text-muted">usage on us</small> -->
            <ul class="list-unstyled mt-3 mb-4">
              <li>Single user</li>
              <li>500 SMS included</li>
              <li>50 MMS included</li>
              <li>$0.03 per additional SMS</li>
              <li>$0.05 per additional SMS</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-primary" v-on:click="onClickGetStarted">Get started</button>
          </div>
        </div>
        <div class="card mb-4 box-shadow" style="min-width: 200px;">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Standard</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">$399 <small class="text-muted">/ mo<sup style="font-size: 14px">*</sup></small></h1>
            <!--<small class="text-muted usage" v-on:click="onClickUsage">+ usage</small>-->
            <ul class="list-unstyled mt-3 mb-4">
              <li>Up to 10 users</li>
              <li>3,000 SMS included</li>
              <li>300 MMS included</li>
              <li>$0.015 per additional SMS</li>
              <li>$0.003 per additional SMS</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-primary" v-on:click="onClickGetStarted">Get started</button>
          </div>
        </div>
        <div class="card mb-4 box-shadow" style="min-width: 200px;">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Standard</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">$799 <small class="text-muted">/ mo<sup style="font-size: 14px">*</sup></small></h1>
            <!--<small class="text-muted usage" v-on:click="onClickUsage">+ usage</small>-->
            <ul class="list-unstyled mt-3 mb-4">
              <li>Up to 50 users</li>
              <li>7,000 SMS included</li>
              <li>700 MMS included</li>
              <li>$0.015 per additional SMS</li>
              <li>$0.003 per additional SMS</li>
            </ul>
            <button type="button" class="btn btn-lg btn-block btn-primary" v-on:click="onClickGetStarted">Get started</button>
          </div>
        </div>

        <div class="card mb-4 box-shadow" style="min-width: 200px;">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Pro</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">Contact us</h1>
            <!--<small class="text-muted usage" v-on:click="onClickUsage">+ usage</small>-->
            <div style="padding-bottom: 25px;">Interested in using RedChirp for your larger organization, franchise or these other plans just don’t fit your unique needs?</div>
            <button type="button" class="btn btn-lg btn-block btn-outline-primary" v-on:click="onClickContactUs">Contact us</button>
          </div>
        </div>

      </div>

      <div style="text-align: left; margin-top: -35px; margin-bottom: 15px;">
        * Plus <a href="https://redchirp.com/wp-content/uploads/2021/09/New-Pricing-Plan-Oct1-2021-fees.pdf" target="_blank">taxes and fees.</a> 
      </div>

      <div>
        <p style="font-size: 3.0em; font-weight: 700">Don't quite fit into any of these plans?</p>
        <p style="font-size: 2.0em; font-weight: 500"><a v-on:click="onClickContactUs" style="text-decoration: underline; color: #df5457; cursor: pointer;">Contact us</a> – we will be happy to work with you.</p>
      </div>
      

      <div class="container" v-if="show_usage">
      <div class="card-deck mb-1 text-center">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Low usage rates mean you don't need to worry</h4>
          </div>
          <div class="card-body">
            
            <ul class="list-unstyled mt-3 mb-4">
              <li><b>Text messages</b> &mdash; $0.01/message</li>
              <li><b>Picture messages</b> &mdash; $0.02/message</li>
              <li><b>Voice</b> &mdash; $0.04/min</li>
              <li><b>Video</b> &mdash; $0.05/min</li>
              <li><b>Local phone numbers</b> &mdash; $1/number/mo</li>
              <li><b>Toll free phone numbers</b> &mdash; $2/number/mo</li>
            </ul>

            In January 2021, the average RedChirp user spent only $1.55 on usage. <b>The heaviest user spent less than $3.00.</b>
            
          </div>
        </div>
       
        </div>
      </div>

      

      
    </div>
    
</m-standard-pageframe>
</template>

<script>
export default {
    data() {
        return {
            show_usage: false,
        }
    },

    methods: {
        onClickUsage() {
            this.show_usage = !this.show_usage;
        },

        onClickGetStarted()
        {
           this.$router.push({ 
            name: 'sign-up',
          });
        },

        onClickContactUs()
        {
          this.$router.push({ 
            name: 'contact-me',
          });
        }
    },
}
</script>

<style lang="scss" scoped>
.usage
{
    text-decoration: underline;
    cursor: pointer;
}
</style>