<template>
<div>
    <m-navbar/>
    <slot/>
    <m-footer/>
</div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>