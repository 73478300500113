<template>
<b-container>
    <b-row style="align-items: center">
        <b-col
          style="text-align: left; margin-top: 2em; margin-bottom: 2em;"
          lg
          :order="content_mobile_order"
          :order-lg="content_desktop_order"
          :order-xl="content_desktop_order"
        >
          <div>
            <slot name="content"/>
          </div>
        </b-col>
        <b-col 
          style="margin-top: 2em; margin-bottom: 2em;"
          lg
          :order="decoration_mobile_order"
          :order-lg="decoration_desktop_order"
          :order-xl="decoration_desktop_order"
        >
            <div>
                <slot name="decoration"/>
            </div>
        </b-col>
      </b-row>
</b-container>
</template>

<script>
export default {
    props: {
        'left' : {
            type: Boolean,
            default: function() { return false; }
        },
    },

    computed: {
        content_desktop_order() 
        {
            return this.left ? 2 : 1;
        },

        content_mobile_order()
        {
            return 1;
        },

        decoration_desktop_order() 
        {
            return this.left ? 1 : 2;
        },

        decoration_mobile_order()
        {
            return 2;
        },
    },
}
</script>

<style lang="scss" scoped>
</style>