<template>
<m-standard-pageframe>

    <b-container
        style="text-align: left;"
        v-if="stage == 'form'"
    >

    <div class="hero_headline mt-3 mb-3">
        Checkin Sample Form
    </div>  

    <div
        style="margin-bottom: 15px;"
    >
        <p>With RedChirp, you can build forms that allow customers to "check in" or "wait on line" using their mobile phones</p>
        
        <p>
            Your can:
            
            <ul>
                <li>Launch the form with a QR code</li>
                <li>Send automatic responses thanking the customer for checking in and letting them know you will get to them as soon as you can.</li>
                 <li>Allow users to cancel their checkin</li>
            </ul>

        </p>

        <p>
            This form is for demonstration purposes and is not monitored.
        </p>
    
    </div>

    <b-form  autocomplete="off">
       <m-text-field
            label="First Name *"
            v-model="first_name"
            autofocus
       />
       
        <m-text-field
            label="Last Name *"
            v-model="last_name"
       />

        <m-text-field
            label="Cell Phone Number *"
            v-model="mobile"
       />

       <m-text-field
            label="What do you need help with?"
            v-model="message"
            textarea
       />

       <small class="text-muted">
           By submitting this form you agree to receive text messages.  Message and/or data rates may apply.
       </small>

       <div style="text-align: right;" class="mb-5">
        <b-btn variant="primary" v-on:click="onClickGetStarted" :disabled="!is_valid">
            Check In!
        </b-btn>
        </div>

    </b-form>

    </b-container>


     <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'spin'"
    >
    <div style="text-align: center; margin-top: 5em; margin-bottom: 5em;">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    </b-container>


    <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'thanks'"
    >
    <div class="hero_headline mt-3 mb-3" style="text-align: center">
        Thanks!

        <div class="hero_tagline mb-5">
            A member of our team will text you shortly!
        </div>
    </div>  
    </b-container>

</m-standard-pageframe>
</template>

<script>
const axios = require('axios');

export default {
    data() {
        return {
            first_name: '',
            last_name: '',
            mobile: '',
            message: '',

            stage: 'form',
        }
    },

    mounted () {
        let value = this.$route.params.value;

        
        if ( value.includes('@') )
            this.email = value;
        else
            this.mobile = value;
    },

    methods: 
    {
        createRequest()
        {
            return {
				'first_name' : this.first_name,
				'last_name' : this.last_name,
				'mobile' : this.mobile,
				'submitted_from_url' : window.location.href,
                'production': true,

				'extra_fields' : [
					{ 'text' : 'question_or_comment', 'value' : this.message },
				],
			};
        },

        onClickGetStarted()
        {
            this.stage = 'spin';

            let axios_data = JSON.stringify(this.createRequest());
            let axios_headers = {'Content-type':'application/json; charset=UTF-8'};

            let axios_request = {
                'url' : 'https://app.redchirp.com/api/v1/contact-requests/form-connect/8a732595-6621-4d6d-af37-0819110ae721/ec439fdb-8aab-41aa-8010-5277cfe1c1ec',
                'method' : 'post',
                'data' : axios_data,
                'headers' : axios_headers,
                'timeout' : 15000, 
            };

            axios.request(axios_request)
            .then( () => {
                this.stage = 'thanks';
            })
            .catch(() => 
                {
                    this.stage = 'form';
                    alert('Unable to submit form.  Please try again.');
                }
            );
        },

        createEndpointFromUSPhoneNumber(number, default_value)
        {
            let only_digits = '';

            for ( let i = 0; i < number.length; i++ )
            {
                let cur_char = number.charAt(i);

                if ( cur_char === '0' || cur_char === '1' || cur_char === '2' || cur_char === '3' || cur_char === '4' || cur_char === '5' || cur_char === '6' || cur_char === '7' || cur_char === '8' || cur_char === '9' )
                {
                    only_digits += cur_char;
                }
            }


            if ( only_digits.length == 10 )
                return 'E164:+1'+only_digits;

            if ( only_digits.length == 11 && only_digits.charAt(0) === '1' )
                return 'E164:+'+only_digits;

            return default_value;
        } ,

        isPhoneNumberValid()
        {
            return this.createEndpointFromUSPhoneNumber(this.mobile,null) != null;
        },

        isBlank(val)
        {
            if ( val == null ) return true;
            if ( val.trim() == '' ) return true;
            return false;
        },

        isEmail(val)
        {
            return !this.isBlank(val) && val.includes('@');
        },

    },

    computed: {
        is_valid() {
            return this.isPhoneNumberValid() && + !this.isBlank(this.first_name) && !this.isBlank(this.last_name);
        }
    },
}
</script>

<style lang="scss" scoped>
</style>