import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes = [
    
];

import VetsStartHere from '@/pages/vets/vets-start-here.vue';

routes.push(
    {
        path: "/vets/start-here",
        name: "vets-start-here",
        component: VetsStartHere,
    }
);

import WineStartHere from '@/pages/wine/wine-start-here.vue';

routes.push(
    {
        path: "/wine/start-here",
        name: "wine-start-here",
        component: WineStartHere,
    }
);

import WineThankYou from '@/pages/wine/wine-thank-you.vue';

routes.push(
    {
        path: "/wine/thankyou",
        name: "wine-thank-you",
        component: WineThankYou,
    }
);

import WineMissedYou from '@/pages/wine/wine-missed-you.vue';

routes.push(
    {
        path: "/wine/missedyou",
        name: "wine-missed-you",
        component: WineMissedYou,
    }
);

import WineCommerce7 from '@/pages/wine/wine-commerce7.vue';

routes.push(
    {
        path: "/wine/commerce7",
        name: "wine-commerce7",
        component: WineCommerce7,
    }
);

import Pricing from '@/pages/pricing.vue';

routes.push(
    {
        path: "/pricing",
        name: "pricing",
        component: Pricing,
    }
);

import GetStarted from '@/pages/sign-up.vue';

routes.push(
    {
        path: "/sign-up/:value",
        name: "sign-up",
        component: GetStarted,
    }
);

import SampleFormHomeCare from '@/pages/demo/sample-form-connect-home-care.vue';

routes.push(
    {
        path: "/demo/sample-form-home-care",
        name: "sample-form-home-care.vue",
        component: SampleFormHomeCare,
    }
);

import SampleFormCheckin from '@/pages/demo/sample-checkin.vue';

routes.push(
    {
        path: "/demo/sample-check-in",
        name: "sample-checkin.vue",
        component: SampleFormCheckin,
    }
);

import SampleFormCheckinStart from '@/pages/demo/sample-checkin-start.vue';

routes.push(
    {
        path: "/demo/sample-check-in-start",
        name: "sample-checkin-start",
        component: SampleFormCheckinStart,
    }
);

import ContactMe from '@/pages/contact-me.vue';

routes.push(
    {
        path: "/contact-me",
        name: "contact-me",
        component: ContactMe,
    }
);

import WineInterest from '@/pages/wine/wine-interest.vue';

routes.push(
    {
        path: "/wine/wine-interest",
        name: "wine-interest",
        component: WineInterest,
    }
);

routes.push(
    {
        path: "/client-pages/studio41/palatine-checkin",
        name: "studio41-palatine-checkin-backwards",
        component: ClientStudio41Checkin,
    }
);


import ClientStudio41Checkin from '@/client-pages/studio41/studio41-checkin.vue';

routes.push(
    {
        path: "/client-pages/studio41/checkin",
        name: "studio41-checkin",
        component: ClientStudio41Checkin,
    }
);

import ClientCuvaisonEmailFooter from '@/client-pages/cuvaison/cuvaison-email-footer.vue';

routes.push(
    {
        path: "/client-pages/cuvaison/cuvaison-email-footer",
        name: "cuvaison-email-footer",
        component: ClientCuvaisonEmailFooter,
    }
);

routes.push(
    {
        path: "/wine*",
        redirect: "/wine/start-here"
    }
);

routes.push(
    {
        path: "/*",
        redirect: "/wine/start-here"
    }
);


const router = new VueRouter({
    mode: "history", 
    routes
  });

export default router;