<template>
<b-form-group
    id="input-group-1"
    :label="label"
    label-for="input-1"
    :description="hint"
>
    <b-form-input
        id="input-1"
        :placeholder="placeholder"
        :required="required"
        :value="value"
        v-on:input="onInput"
        autocomplete="nope"
        type="text"
        data-lpignore="true"
        :autofocus="autofocus"
        v-if="!textarea"
    >
    </b-form-input>
     <b-form-textarea
        id="input-1"
        :placeholder="placeholder"
        :required="required"
        :value="value"
        v-on:input="onInput"
        autocomplete="nope"
        type="text"
        data-lpignore="true"
        :autofocus="autofocus"
        v-if="textarea"
    >
    </b-form-textarea>
</b-form-group>
</template>

<script>
export default {
    props: {

        label: {
            type: String,
            default: function() { return "Label"; }
        },

        hint: {
            type: String,
            default: function() { return null; }
        },

        placeholder: {
            type: String,
            default: function() { return null; }
        },

        required: {
            type: Boolean,
            defualt: function() { return false; }
        },

        value: {
            type: String,
            defualt: function() { return ""; }
        },

        autofocus: {
            type: Boolean,
            default: function() { return false; }
        },

        textarea: {
            type: Boolean,
            default: function() { return false; }
        },
    },

    methods: {
        onInput(new_value) 
        {
            this.$emit('input',new_value);
        }
    },

}
</script>

<style lang="scss" scoped>

</style>