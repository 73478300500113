<template>
<m-standard-pageframe>

    <b-container
        style="text-align: left;"
        v-if="stage == 'form'"
    >

    <div class="hero_headline mt-3 mb-3">
        Try RedChirp!
    </div>  

    <div style="margin-bottom: 15px;">
        Thanks for attending our event! You can try RedChirp for free for 30 days. Just complete this quick form to qualify:
    </div>

    <b-form  autocomplete="off">
       <m-text-field
            label="First Name *"
            v-model="first_name"
            autofocus
       />
       
        <m-text-field
            label="Last Name *"
            v-model="last_name"
       />

       <m-text-field
            label="Business Name"
            v-model="business_name"
       />

        <m-text-field
            label="Cell Phone Number *"
            v-model="mobile"
       />

       <m-text-field
            label="What interests you the most about RedChirp?"
            v-model="what_interests_you_most"
       />

       <small class="text-muted">
           By submitting this form, you give us permission to send text messages with info, promotions and offers to the number you provided. Please note, we may use automation to send these texts. Consent is not a condition of purchase. Message and/or data rates may apply.
       </small>

       <div style="text-align: right;" class="mb-5">
        <b-btn variant="primary" v-on:click="onClickGetStarted" :disabled="!is_valid">
            Submit
        </b-btn>
        </div>

    </b-form>

    </b-container>


     <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'spin'"
    >
    <div style="text-align: center; margin-top: 5em; margin-bottom: 5em;">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    </b-container>


    <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'thanks'"
    >
    <div class="hero_headline mt-3 mb-3" style="text-align: center">
        Cheers!

        <div class="hero_tagline mb-5">
            Someone will text you soon to follow up.
        </div>
    </div>  
    </b-container>

</m-standard-pageframe>
</template>

<script>
const axios = require('axios');

export default {
    data() {
        return {
            first_name: '',
            last_name: '',
            mobile: '',
            business_name: '',
            what_interests_you_most: '',

            stage: 'form',
        }
    },

    mounted () {
        localStorage.setItem('industry','wine');
    },

    methods: 
    {
        createRequest()
        {
            return {
				'first_name' : this.first_name,
				'last_name' : this.last_name,
				'mobile' : this.mobile,
				'submitted_from_url' : window.location.href,
                'production': true,

				'extra_fields' : [
                    { 'text' : 'business_name', 'value' : this.business_name },
					{ 'text' : 'what_interests_you_most', 'value' : this.what_interests_you_most },
				],
			};
        },

        onClickGetStarted()
        {
            this.stage = 'spin';

            let axios_data = JSON.stringify(this.createRequest());
            let axios_headers = {'Content-type':'application/json; charset=UTF-8'};

            let axios_request = {
                'url' : 'https://app.redchirp.com/api/v1/contact-requests/form-connect/53485c43-8d64-4be9-82fa-6ae2b4efe575/a4ebbd15-6eef-4c78-ba5d-38fec2fc2806',
                'method' : 'post',
                'data' : axios_data,
                'headers' : axios_headers,
                'timeout' : 15000, 
            };

            axios.request(axios_request)
            .then( () => {
                this.stage = 'thanks';
            })
            .catch(() => 
                {
                    this.stage = 'form';
                    alert('Unable to submit form.  Please try again.');
                }
            );
        },

        createEndpointFromUSPhoneNumber(number, default_value)
        {
            let only_digits = '';

            for ( let i = 0; i < number.length; i++ )
            {
                let cur_char = number.charAt(i);

                if ( cur_char === '0' || cur_char === '1' || cur_char === '2' || cur_char === '3' || cur_char === '4' || cur_char === '5' || cur_char === '6' || cur_char === '7' || cur_char === '8' || cur_char === '9' )
                {
                    only_digits += cur_char;
                }
            }


            if ( only_digits.length == 10 )
                return 'E164:+1'+only_digits;

            if ( only_digits.length == 11 && only_digits.charAt(0) === '1' )
                return 'E164:+'+only_digits;

            return default_value;
        } ,

        isPhoneNumberValid()
        {
            return this.createEndpointFromUSPhoneNumber(this.mobile,null) != null;
        },

        isBlank(val)
        {
            if ( val == null ) return true;
            if ( val.trim() == '' ) return true;
            return false;
        },

        isEmail(val)
        {
            return !this.isBlank(val) && val.includes('@');
        },

    },

    computed: {
        is_valid() {
            return this.isPhoneNumberValid() && + !this.isBlank(this.first_name) && !this.isBlank(this.last_name);
        }
    },
}
</script>

<style lang="scss" scoped>
</style>