<template>
<div>

    <img
        style="margin-top: 25px; margin-bottom: 15px; width: 350px" 
        src="@/client-pages/cuvaison/cuvaison-logo.jpeg"
    />

    <b-container
        style="text-align: left;"
        v-if="stage == 'form'"
    >

    <!--
    <div class="hero_headline mt-3 mb-3" style="text-align: center">
        cuvaison
    </div>  
    -->

    <b-form  autocomplete="off">

       <m-text-field
            label="First Name *"
            v-model="first_name"
            :autofocus="showroom != null"
       />
       
        <m-text-field
            label="Last Name *"
            v-model="last_name"
       />

        <m-text-field
            label="Cell Phone Number *"
            v-model="mobile"
       />

       <m-text-field
            label="What do you need help with today?"
            v-model="message"
            textarea
       />

       <small class="text-muted">
           By submitting this form, you give us permission to send text messages with info, promotions and offers to the number you provided. Please note, we may use automation to send these texts. Consent is not a condition of purchase. Message and/or data rates may apply.
       </small>

       <div style="text-align: right;" class="mb-5">
        <b-btn variant="primary" v-on:click="onClickSubmit" :disabled="!is_valid">
            Submit
        </b-btn>
        </div>

    </b-form>

    </b-container>


     <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'spin'"
    >
    <div style="text-align: center; margin-top: 5em; margin-bottom: 5em;">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    </b-container>


    <b-container
        style="text-align: left;"
        class="mt-5"
        v-if="stage == 'thanks'"
    >
    <div class="hero_headline mt-3 mb-3" style="text-align: center">
        Thanks! A member of our team will get back to you shortly!

        <div style="text-align: right;" class="mb-5">
            <b-btn variant="primary" v-on:click="onClickSendAnother">
                Send another message
            </b-btn>
        </div>
    </div>  
    </b-container>

</div>
</template>

<script>
const axios = require('axios');

export default {
    data() {
        return {
            first_name: '',
            last_name: '',
            mobile: '',
            message: '',

            stage: 'form',
        }
    },

    mounted () {
    },

    methods: 
    {
        createRequest()
        {
            return {
				'first_name' : this.first_name,
				'last_name' : this.last_name,
				'mobile' : this.mobile,
				'submitted_from_url' : window.location.href,
                'production': true,
                'message' : this.message,

				'extra_fields' : [
					//{ 'text' : 'needs_help_with', 'value' : this.message },
				],
			};
        },

        onClickSendAnother()
        {   
            this.first_name = '';
            this.last_name = '';
            this.mobile = '';
            this.message = '';
            this.stage = 'form';
        },

        onClickSubmit()
        {
            this.stage = 'spin';

            let axios_data = JSON.stringify(this.createRequest());
            let axios_headers = {'Content-type':'application/json; charset=UTF-8'};
            let url = 'https://app.redchirp.com/api/v1/contact-requests/form-connect/af9b71bc-09df-41ad-a473-c2a5b941f7fd/cfd6fc5a-f6e9-42bd-8684-bccd6ed29036';

            let axios_request = {
                'url' : url,
                'method' : 'post',
                'data' : axios_data,
                'headers' : axios_headers,
                'timeout' : 15000, 
            };

            axios.request(axios_request)
            .then( () => {
                this.stage = 'thanks';
            })
            .catch(() => 
                {
                    this.stage = 'form';
                    alert('Unable to submit form.  Please try again.');
                }
            );
        },

        createEndpointFromUSPhoneNumber(number, default_value)
        {
            let only_digits = '';

            for ( let i = 0; i < number.length; i++ )
            {
                let cur_char = number.charAt(i);

                if ( cur_char === '0' || cur_char === '1' || cur_char === '2' || cur_char === '3' || cur_char === '4' || cur_char === '5' || cur_char === '6' || cur_char === '7' || cur_char === '8' || cur_char === '9' )
                {
                    only_digits += cur_char;
                }
            }


            if ( only_digits.length == 10 )
                return 'E164:+1'+only_digits;

            if ( only_digits.length == 11 && only_digits.charAt(0) === '1' )
                return 'E164:+'+only_digits;

            return default_value;
        } ,

        isPhoneNumberValid()
        {
            return this.createEndpointFromUSPhoneNumber(this.mobile,null) != null;
        },

        isBlank(val)
        {
            if ( val == null ) return true;
            if ( val.trim() == '' ) return true;
            return false;
        },

        isEmail(val)
        {
            return !this.isBlank(val) && val.includes('@');
        },

    },

    computed: {
        is_valid() {
            return this.isPhoneNumberValid() && + !this.isBlank(this.first_name) && !this.isBlank(this.last_name);
        }
    },
}
</script>

<style lang="scss" scoped>
</style>