<template>
<m-standard-pageframe>

    <b-container
        style="text-align: center;"
    >

     <div class="hero_headline mt-3 mb-3">
        RedChirp Check In Demonstration
    </div>  

        <img src="@/assets/qr-code-checkin-sample.png" width="450px"/>

        <br/>

        Scan the QR code on your phone to check in!<br/><br/>
        In actual use, you would print the above QR code out on a sign, poster or flyer.<br/><br/>
        You can also check in by <a href="https://www.red-chirp.com/demo/sample-check-in">clicking here</a>

        <br/>
        <br/>

    </b-container>


</m-standard-pageframe>
</template>

<script>

export default {
    data() {
        return {
        }
    },

   
}
</script>

<style lang="scss" scoped>
</style>