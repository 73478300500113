<template>
<ul class="fa-ul checklist">
    <slot/>
</ul>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
.checklist
{
  margin-top: 15px;
}

.checklist li i
{
  color: var(--primary);
}

.checklist li:not(:last-child) {
    margin-bottom: 15px;
}
</style>