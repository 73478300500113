<template>
<li>
    <i class="fa-li fa fa-check"></i>
    <slot/>
</li>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
</style>