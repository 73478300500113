import { render, staticRenderFns } from "./wine-interest.vue?vue&type=template&id=2086556e&scoped=true&"
import script from "./wine-interest.vue?vue&type=script&lang=js&"
export * from "./wine-interest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2086556e",
  null
  
)

export default component.exports